import React, { useCallback, useContext, useEffect, useState } from "react";

import { getActiveSession_getActiveSession_expert } from "@layout/sessionController/graphql/__generated__/getActiveSession";
import moment from "moment";

import Duration from "@/components/chat/Duration";
import GlobalContext from "@/contexts/Global/GlobalContext";
import FastPayment from "@components/common/fastPayment";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import SessionWaitingBlock from "@components/common/sessionWaitingBlock";

import GiftJsonAnimation from "../common/giftJsonAnimation";
import TextChatDuration from "../textChat/textChatDuration";

import GiftContainer from "./Gifts";
import GiftButton from "./Gifts/components/giftButton";
import GiftNotification from "./Gifts/components/giftButton/giftNotification";
import { getProductHistory_getProductHistory } from "./graphql/__generated__/getProductHistory";
import { ChatProps } from "./types";

import "./styles.scss";

const Chat = (props: ChatProps) => {
  const {
    sessionId,
    enableVideo,
    noVideoDevice,
    isVideo,
    isRemoteVideoOn,
    isAudio,
    isConnected,
    isButtonDisabled,
    endSessionEvent,
    toggleMicrophone,
    toggleCamera,
    receiver,
    startTimestamp,
    outOfMoneyTime,
    sessionRefetch,
    paidGifts,
  } = props;

  const [isFullscreen, setFullscreenMode] = useState(true);
  // todo: maybe move to separate context
  const [isSessionEndsSoon, setIsSessionEndsSoon] = useState(false);
  const [isShowGiftsBlock, setIsShowGiftsBlock] = useState(false);
  const [clientGiftHistory, setClientGiftHistory] = useState<string[]>([]);
  const [giftForExpert, setGiftForExpert] = useState<
    (getProductHistory_getProductHistory | null)[]
  >([]);

  const { isExpert } = useContext(GlobalContext);

  const sessionEndsSoonCallback = useCallback(
    (isEndsSoon: boolean) => {
      if (isEndsSoon !== isSessionEndsSoon) {
        // todo: most likely delete check
        setIsSessionEndsSoon(isEndsSoon);
      }
    },
    [isSessionEndsSoon]
  );

  useEffect(() => {
    if (paidGifts.length && paidGifts[0]?.status === "processed") {
      setGiftForExpert(paidGifts);
    }

    const removeTimeoutForHideNotification = setTimeout(() => {
      setGiftForExpert([]);
    }, 30000);

    return () => clearTimeout(removeTimeoutForHideNotification);
  }, [paidGifts]);

  const toggleVideo = () => {
    toggleCamera(!isVideo);
  };

  const toggleAudio = () => {
    toggleMicrophone(!isAudio);
  };

  const formatDateOfBirthUser = receiver?.dateOfBirth
    ? moment(receiver?.dateOfBirth).format("DD.MM.YY")
    : "—";

  const userName = receiver.name ? receiver.name : "—";

  return (
    <div className="page-width chat__outer">
      <div className="chat__container">
        <div className="chat__left chat__column border">
          {enableVideo ? (
            <div className={`video ${isFullscreen ? "video--fullscreen" : ""}`}>
              {isExpert && (
                <div className="video__info-block">
                  <p>{userName}</p>
                  <p>{formatDateOfBirthUser}</p>
                </div>
              )}
              <div
                sentry-block
                className={`video__player video__local ${isVideo ? "show" : ""} ${
                  !isConnected && !isRemoteVideoOn ? "remote-video-disabled" : ""
                }`}
                id="video-local"
              />
              <div
                sentry-block
                className={`video__player video__remote ${isRemoteVideoOn ? "show" : ""}`}
                id="video-remote"
              />
              <SessionWaitingBlock
                text={`Ожидание ${isExpert ? "клиента" : "эксперта"}...`}
                subtextTop={`${isExpert ? "" : "Эксперт подключится в\u00a0течение 2 минут."}`}
                subtextBottom={`${
                  isExpert ? "" : "Минуты начнут списываться, только когда эксперт подключится"
                }`}
                isRemoteVideoOn={isRemoteVideoOn}
                isConnected={isConnected}
                receiver={receiver}
                showCarousel={!isExpert && !isConnected}
              />
              {!isExpert && isConnected && (
                <FastPayment
                  isSessionEndsSoon={isSessionEndsSoon}
                  expertRate={
                    (receiver as getActiveSession_getActiveSession_expert).rates.videoCall
                  }
                  sessionRefetch={sessionRefetch}
                  isShowGiftsBlock={isShowGiftsBlock}
                  setIsShowGiftsBlock={setIsShowGiftsBlock}
                  sessionId={sessionId}
                />
              )}

              {!isExpert && isConnected && (
                <GiftContainer
                  sessionId={sessionId}
                  isShow={isShowGiftsBlock}
                  setIsShowGiftsBlock={setIsShowGiftsBlock}
                  setClientGiftHistory={setClientGiftHistory}
                  clientGiftHistory={clientGiftHistory}
                />
              )}
              {isExpert && giftForExpert.length && isConnected && (
                <GiftNotification giftForExpert={giftForExpert} />
              )}
              {isConnected && !isExpert && (
                <div onClick={() => setIsShowGiftsBlock(!isShowGiftsBlock)}>
                  <GiftButton
                    startTimestamp={startTimestamp}
                    clientGaveFirstGift={!!clientGiftHistory.length}
                  />
                </div>
              )}
              {isConnected && !isExpert && (
                <div className="chat__gift-history-block">
                  {clientGiftHistory.map((animation, idx) => (
                    <div className="gift-history-element" key={idx}>
                      <GiftJsonAnimation animationPath={animation} />
                    </div>
                  ))}
                </div>
              )}

              <div className="chat__duration-container">
                {isConnected && isExpert && (
                  <TextChatDuration timestamp={outOfMoneyTime} background />
                )}
                {!isExpert && isConnected && outOfMoneyTime && (
                  <Duration
                    timestamp={outOfMoneyTime}
                    isReverse
                    sessionEndsSoonCallback={sessionEndsSoonCallback}
                  />
                )}
              </div>
              <div className="video__controls">
                <div
                  className={`video__btn video__btn--video ${
                    isButtonDisabled || noVideoDevice ? "disabled" : ""
                  }`}
                  onClick={toggleVideo}
                >
                  {isVideo ? (
                    <Icon type={IconTypeEnum.Video} size={IconSizeEnum.Size32} />
                  ) : (
                    <Icon type={IconTypeEnum.VideoOff} size={IconSizeEnum.Size32} />
                  )}
                </div>
                <div
                  className={`video__btn video__btn--cancel ${isButtonDisabled ? "disabled" : ""}`}
                  onClick={endSessionEvent}
                >
                  <Icon type={IconTypeEnum.CallEnd} size={IconSizeEnum.Size48} />
                </div>
                <div
                  className={`video__btn video__btn--microphone ${
                    isButtonDisabled ? "disabled" : ""
                  }`}
                  onClick={toggleAudio}
                >
                  {isAudio ? (
                    <Icon type={IconTypeEnum.Microphone} size={IconSizeEnum.Size32} />
                  ) : (
                    <Icon type={IconTypeEnum.MicrophoneOff} size={IconSizeEnum.Size32} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
